<template>
	<div class="main-container" v-if="computedValue === `ko`">
		<div class="maincontent--1">
			<hooper :autoPlay="true" :playSpeed="3000" :wheelControl="false">
				<slide>
					<div
						:style="{ backgroundImage: `url(${this.main1Img})` }"
						class="mainbanner__img--1"
					>
						<div class="mainbanner__text--1">
							<div v-scrollanimation>
								<h2>FCTS solution based on our patent Crypto</h2>
								<h1>자사 특허 암호화 기반으로 한 FCTS 솔루션</h1>
								<h3>
									수학 기반 알고리즘 암호화 솔루션으로 더욱 빠르고 보다 안전하게
									새로운 방법을 제시합니다.
								</h3>
							</div>
						</div>
					</div>
				</slide>
				<slide>
					<div
						:style="{ backgroundImage: `url(${this.main2Img})` }"
						class="mainbanner__img--2"
					>
						<div class="mainbanner__text--2">
							<div v-scrollanimation>
								<h2>CHALLENGE TO INFINITE TECHNOLOGY</h2>
								<h1>무한한 기술에 대한 도전</h1>
								<h3>눈앞에 펼쳐지는 미래 시대를 FCTS와 함께하세요.</h3>
							</div>
						</div>
					</div>
				</slide>
				<slide>
					<div
						:style="{ backgroundImage: `url(${this.main3Img})` }"
						class="mainbanner__img--3"
					>
						<div class="mainbanner__text--3">
							<div v-scrollanimation>
								<h2>FCTS’s simple API method through developer site</h2>
								<h1>
									FCTS 서비스는 누구나 쉽고 빠르게 접근 가능한 API를 제공합니다
								</h1>
								<h3>
									개발자 사이트를 통하여 간단한 api 연동 방식을 제공하여 보다
									쉽고 빠르게 접근이 가능합니다.
								</h3>
							</div>
						</div>
					</div>
				</slide>
				<hooper-pagination slot="hooper-addons"></hooper-pagination>
			</hooper>
		</div>
		<!-- <div v-if="showPopup" class="popup">
			<div class="popup-content">
				<img :src="popup" />
				<a href="https://forms.gle/Rb5ZeDbpd6qKbNCDA" target="_blank">
					<button class="PriceBtn">
						<div class="popupFont">사전등록 하러가기</div>
						<img class="popupSean" :src="popupSean" />
					</button>
				</a>
				<div class="buttonBox">
					<button class="AlldayClose" @click="disablePopup">
						하루동안 보지않기
					</button>
					<button class="Close" @click="closePopup">닫기</button>
				</div> -->
		<!-- <s class="close" @click="closePopup">&times;</s
					pan> -->
		<!-- </div>
		</div> -->

		<div class="maincontent--2">
			<h2>
				FCTS SERVICE<br />
				<span>4차 산업혁명 시대 서비스를 주도합니다</span>
			</h2>
			<p>
				대표적인 FCTS의 서비스로 업무 효율부터 보안까지<br />새로운 패러다임으로
				여러분과 함께 합니다
			</p>
			<div class="gap-20">
				<div class="card__wrapper">
					<div class="img1">
						<div class="card__main">
							<div class="card__img" style="padding: 0">
								<img :src="cryptoImg" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">암호 스키마</div>
								<div class="card__description">
									FCTS는 연구진들의 원론적인 수학 기반 연구를 통해 기본 동형
									암호의 단점은 보완 하고성능은 향상된 암호를 제공합니다
								</div>
							</div>
						</div>
					</div>
					<div class="img2">
						<div class="card__main">
							<div class="card__imgMo" style="padding: 0">
								<img :src="securityImg" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">모듈러스</div>
								<div class="card__description">
									실시간 데이터 가시화를 지원하는 데이터 분석 프레임워크로
									안전한 보안 환경에서의 협업과 동형 분석 기능을 탑재하여 쉽고
									편리하며 안전한 데이터 분석 서비스를 제공합니다.
								</div>
							</div>
						</div>
					</div>
					<div class="img3">
						<div class="card__main">
							<div class="card__img" style="padding: 0">
								<img :src="vaccineImg" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">백신 FC-Anti Virus</div>
								<div class="card__description">
									FCTS는 다양한 유형의 악성코드를 미리 탐지하여 기업에서
									사용하는 PC를 보호하고 감염된 PC를 치료하는 백신 서비스를
									제공합니다.
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="img3">
					<div class="card__main">
						<div class="card__img">
							<img :src="vaccineImg" class="card__img--service" />
						</div>
						<div class="card__text">
							<div class="card__title">BI Mining</div>
							<div class="card__description">
								FCTS는 다양한 유형의 악성코드를 미리 탐지하여 기업에서 사용하는
								PC를 보호하고 감염된 PC를 치료하는 백신 서비스를 제공합니다.
							</div>
						</div>
					</div>
				</div> -->
				</div>
				<div class="card__wrapper">
					<div class="img1">
						<div class="card__main">
							<div class="card__img" style="padding: 0">
								<img :src="content4img4" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">CryptaLedger Tech</div>
								<div class="card__description">
									타원곡선 기반 동형암호와 분산원장 기술을 활용해 데이터 암호화
									상태에서도 복호화 없이 거래의 안전성과 기밀성을 유지하며 계정
									및 트랜잭션 정보를 보호하는 혁신적인 서비스를 제공합니다.
								</div>
							</div>
						</div>
					</div>
					<div class="img2">
						<div class="card__main">
							<div class="card__imgMo" style="padding: 0">
								<img :src="content4img5" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">BI Mining</div>
								<div class="card__description">
									비즈니스 분석 데이터를 손쉽게 시각화할 수 있는 도구로, 자동
									차트 생성, 엑셀 수정, 그리고 보고서 작성 기능을 하나의 툴에서
									모두 제공하여 업무 효율을 극대화하는 서비스를 제공합니다.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 메인 콘텐츠 3 -->
		<div class="maincontent--3">
			<div class="maincontent3_contentbox">
				<img :src="content3img1" class="content3_img_pc" />
				<img :src="content3img2" class="content3_img_tablet" />
				<img :src="content3img3" class="content3_img_mobile" />
				<img :src="content3img4" class="content3_img_mobile2" />
			</div>
		</div>
		<!-- 메인 콘텐츠 4 -->
		<div class="maincontent--4">
			<div class="maincontent--4__title">
				<h2>추천 서비스</h2>
				<h3>
					FCTS만의 특별한 서비스로 클라이언트에게 알맞은<br />인프라를 선택하여
					구매할 수 있습니다
				</h3>
			</div>
			<div class="contentbox__wrapper">
				<div class="maincontent--4__contentbox">
					<img :src="content4img1" />

					<div class="maincontent--4__textbox">
						<h3>FCTS 암호</h3>
						<p>
							FCTS는 연구진들의 원론적인 수학 기반 연구를 통해 기존 동형 암호의
							단점은 보완하고 성능은 향상된 암호를 제공합니다.
						</p>
					</div>
				</div>
				<div class="maincontent--4__contentbox2">
					<img :src="content4img2" />

					<div class="maincontent--4__textbox2">
						<h3>FCTS 데이터 분석</h3>
						<p>
							FCTS는 데이터 분석 과정에 특화된 프레임워크를 제공하여 데이터의
							가시화부터 데이터 분석용 서버 구성까지 모든 과정을 편리하게
							이용하실 수 있습니다.
						</p>
					</div>
				</div>
				<div class="maincontent--4__contentbox3">
					<img :src="content4img3" />

					<div class="maincontent--4__textbox3">
						<h3>FCTS 백신</h3>
						<p>
							기존의 악성코드 탐지 유사도 알고리즘과 달리, FCTS는 자체적으로
							연구 개발한 수학적 구조 기반의 유사도 알고리즘을 적용하여 높은
							성능의 백신을 제공합니다.
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--maincontent5-->
		<div class="maincontent--5">
			<div class="maincontent--5_title">
				<h2>
					요금 결제 시스템<br />
					<span>비용 부담은 줄이고, 효율성은 더 높게!</span>
				</h2>
			</div>
			<div class="maincontent--5_img">
				<img :src="content5img" />
			</div>
			<div class="maincontent--5_textbox">
				<p>
					기존 암호의 경우, 특허 저작권으로 인해 라이센스 비용이 청구 되는
					반면,<br />
					FCTS는 자체적으로 개발한 암호 스키마 기술 이용으로 인해<br />
					보다 절감된 가격으로 향샹된 서비스를 이용하실 수 있습니다.<br />
				</p>
			</div>
			<div class="maincontent--5_button">
				<router-link to="/pricing">요금 확인 하기</router-link>
			</div>
		</div>

		<div class="maincontent--6">
			<h2>FCTS 솔루션에 대해 더 궁금하신가요?</h2>
			<h3>
				<router-link to="/inquiry">1:1 문의하기</router-link> |
				<router-link to="/faq">자주하는 질문</router-link>
			</h3>
		</div>
	</div>
	<div class="main-container" v-else-if="computedValue === `en`">
		<div class="maincontent--1">
			<hooper :autoPlay="true" :playSpeed="3000" :wheelControl="false">
				<slide>
					<div
						:style="{ backgroundImage: `url(${this.main1Img})` }"
						class="mainbanner__img--1"
					>
						<div class="mainbanner__text--1">
							<div v-scrollanimation>
								<h2>FCTS solution based on our patent Crypto</h2>
								<h1>FCTS solution based on our patented crypto system</h1>
								<h3>
									We present a new method that is faster and more secure through
									a mathematics-based algorithm crypto system solution.
								</h3>
							</div>
						</div>
					</div>
				</slide>
				<slide>
					<div
						:style="{ backgroundImage: `url(${this.main2Img})` }"
						class="mainbanner__img--2"
					>
						<div class="mainbanner__text--2">
							<div v-scrollanimation>
								<h2>CHALLENGE TO INFINITE TECHNOLOGY</h2>
								<h1>Challenge of Infinite Technology</h1>
								<h3>
									Embrace the future unfolding before your eyes with FCTS.
								</h3>
							</div>
						</div>
					</div>
				</slide>
				<slide>
					<div
						:style="{ backgroundImage: `url(${this.main3Img})` }"
						class="mainbanner__img--3"
					>
						<div class="mainbanner__text--3">
							<div v-scrollanimation>
								<h2>FCTS’s simple API method through developer site</h2>
								<h1>
									The FCTS service offers an API that anyone can easily and
									quickly access.
								</h1>
								<h3>
									Through the developer site, we provide a simple API
									integration method, allowing for easier and quicker access.
								</h3>
							</div>
						</div>
					</div>
				</slide>
				<hooper-pagination slot="hooper-addons"></hooper-pagination>
			</hooper>
		</div>
		<!-- <div v-if="showPopup" class="popup">
			<div class="popup-content">
				<img :src="popup" />
				<a href="https://forms.gle/Rb5ZeDbpd6qKbNCDA" target="_blank">
					<button class="PriceBtn">
						<div class="popupFont">사전등록 하러가기</div>
						<img class="popupSean" :src="popupSean" />
					</button>
				</a>
				<div class="buttonBox">
					<button class="AlldayClose" @click="disablePopup">
						하루동안 보지않기
					</button>
					<button class="Close" @click="closePopup">닫기</button>
				</div> -->
		<!-- <s class="close" @click="closePopup">&times;</s
					pan> -->
		<!-- </div>
		</div> -->
		<div class="maincontent--2">
			<h2>
				FCTS SERVICE<br />
				<span
					>leads the way in the era of the Fourth Industrial Revolution.</span
				>
			</h2>
			<p>
				As a representative service of FCTS, we partner with you to <br />
				enhance everything from work efficiency to security in a new paradigm.
			</p>
			<div class="gap-20">
				<div class="card__wrapper">
					<div class="img1">
						<div class="card__main">
							<div class="card__img">
								<img :src="cryptoImg" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">Crypto Schema</div>
								<div class="card__description">
									FCTS provides an enhanced encryption solution that addresses
									the shortcomings of basic homomorphic encryption through
									foundational mathematical research conducted by our research
									team.
								</div>
							</div>
						</div>
					</div>
					<div class="img2">
						<div class="card__main">
							<div class="card__imgMo">
								<img :src="securityImg" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">Modulus</div>
								<div class="card__description">
									We provide a data analysis framework that supports real-time
									data visualization, equipped with collaboration features in a
									secure environment and homomorphic analysis capabilities,
									offering an easy, convenient, and secure data analysis
									service.
								</div>
							</div>
						</div>
					</div>
					<div class="img3">
						<div class="card__main">
							<div class="card__img">
								<img :src="vaccineImg" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">FC-Anti Virus</div>
								<div class="card__description">
									FCTS provides antivirus services that detect various types of
									malware in advance, protecting PCs used by businesses and
									healing infected PCs.
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="img3">
					<div class="card__main">
						<div class="card__img">
							<img :src="vaccineImg" class="card__img--service" />
						</div>
						<div class="card__text">
							<div class="card__title">BI Mining</div>
							<div class="card__description">
								FCTS는 다양한 유형의 악성코드를 미리 탐지하여 기업에서 사용하는
								PC를 보호하고 감염된 PC를 치료하는 백신 서비스를 제공합니다.
							</div>
						</div>
					</div>
				</div> -->
				</div>
				<div class="card__wrapper">
					<div class="img1">
						<div class="card__main">
							<div class="card__img" style="padding: 0">
								<img :src="content4img4" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">CryptaLedger Tech</div>
								<div class="card__description">
									Using elliptic curve-based homomorphic encryption and
									distributed ledger technology, we provide an innovative
									service that protects account and transaction information
									while maintaining transaction security and confidentiality
									without the need for decryption, even in encrypted data
									states.
								</div>
							</div>
						</div>
					</div>
					<div class="img2">
						<div class="card__main">
							<div class="card__imgMo" style="padding: 0">
								<img :src="content4img5" class="card__img--service" />
							</div>
							<div class="card__text">
								<div class="card__title">BI Mining</div>
								<div class="card__description">
									A tool that allows you to easily visualize business analysis
									data, offering automatic chart creation, Excel editing, and
									report generation all in one platform to maximize work
									efficiency.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 메인 콘텐츠 3 -->
		<div class="maincontent--3">
			<div class="maincontent3_contentbox">
				<img :src="content3img1en" class="content3_img_pc" />
				<img :src="content3img2en" class="content3_img_tablet" />
				<img :src="content3img3en" class="content3_img_mobile" />
				<img :src="content3img4en" class="content3_img_mobile2" />
			</div>
		</div>
		<!-- 메인 콘텐츠 4 -->
		<div class="maincontent--4">
			<div class="maincontent--4__title">
				<h2>Recommended Services</h2>
				<h3>
					With FCTS's unique offerings, clients can choose and<br />purchase the
					infrastructure that best suits their needs.
				</h3>
			</div>
			<div class="contentbox__wrapper">
				<div class="maincontent--4__contentbox">
					<img :src="content4img1" />

					<div class="maincontent--4__textbox">
						<h3>FCTS Crypto system</h3>
						<p>
							FCTS provides an enhanced solution that addresses the shortcomings
							of existing homomorphic encryption through foundational
							mathematical research conducted by our research team.
						</p>
					</div>
				</div>
				<div class="maincontent--4__contentbox2">
					<img :src="content4img2" />

					<div class="maincontent--4__textbox2">
						<h3>FCTS Data Analysis</h3>
						<p>
							FCTS offers a framework specialized for data analysis, allowing
							users to conveniently utilize all processes, from data
							visualization to the configuration of data analysis servers.
						</p>
					</div>
				</div>
				<div class="maincontent--4__contentbox3">
					<img :src="content4img3" />

					<div class="maincontent--4__textbox3">
						<h3>FCTS Data Analysis</h3>
						<p>
							FCTS offers a framework specialized for data analysis, allowing
							users to conveniently utilize all processes, from data
							visualization to the configuration of data analysis servers.
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--maincontent5-->
		<div class="maincontent--5">
			<div class="maincontent--5_title">
				<h2>
					Billing System<br />
					<span>Reduce costs while increasing efficiency!</span>
				</h2>
			</div>
			<div class="maincontent--5_img">
				<img :src="content5img" />
			</div>
			<div class="maincontent--5_textbox">
				<p>
					In the case of existing encryptions, licensing fees are charged<br />due
					to patent rights. In contrast, with FCTS's use of self-developed<br />crypto
					schema technology, you can access enhanced services at a<br />more
					reduced cost.
				</p>
			</div>
			<div class="maincontent--5_button">
				<router-link to="/pricing">Check pricing</router-link>
			</div>
		</div>

		<div class="maincontent--6">
			<h2>Are you curious to know more about the FCTS solution?</h2>
			<h3>
				<router-link to="/inquiry">1:1 Contact</router-link> |
				<router-link to="/faq">Frequently Asked Questions</router-link>
			</h3>
		</div>
	</div>
</template>

<script>
import popup from '@/assets/images/main/popup.png';
import popupSean from '@/assets/images/main/popupSean.svg';
import main1Img from '@/assets/images/main/maindbanner1.svg';
import main2Img from '@/assets/images/main/maindbanner2.svg';
import main3Img from '@/assets/images/main/maindbanner3.svg';
import securityImg from '@/assets/images/main/securityImg.png';
import cryptoImg from '@/assets/images/main/crypto.svg';
import vaccineImg from '@/assets/images/main/vaccine.svg';
import content4img1 from '@/assets/images/main/main_content4_img1.svg';
import content4img2 from '@/assets/images/main/main_content4_img2.svg';
import content4img3 from '@/assets/images/main/main_content4_img3.svg';
import content4img4 from '@/assets/images/main/main_content4_img4.svg';
import content4img5 from '@/assets/images/main/main_content4_img5.svg';
import content7 from '@/assets/images/main/Group133.svg';
import content5img from '@/assets/images/main/cost-performance.svg';
import content3img1 from '@/assets/images/main/content3img1.svg';
import content3img2 from '@/assets/images/main/content3img2.svg';
import content3img3 from '@/assets/images/main/content3img3.svg';
import content3img4 from '@/assets/images/main/content3img4.svg';
import content3img1en from '@/assets/images/main/content3img1en.svg';
import content3img2en from '@/assets/images/main/content3img2en.svg';
import content3img3en from '@/assets/images/main/content3img3en.svg';
import content3img4en from '@/assets/images/main/content3img4en.svg';
// import Carousel from '@/components/common/Carousel.vue';
// import CarouselSlide from '@/components/common/CarouselSlide.vue';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
	data: function () {
		return {
			showPopup: false,
			mainImage: 1,
			service: 1,
			main1Img: main1Img,
			main2Img: main2Img,
			main3Img: main3Img,
			securityImg: securityImg,
			cryptoImg: cryptoImg,
			vaccineImg: vaccineImg,
			isActive1: true,
			isActive2: false,
			isActive3: false,
			isActive4: false,
			content4img1,
			content4img2,
			content4img3,
			content4img4,
			content4img5,
			content7,
			popup,
			popupSean,
			content5img,
			content3img1,
			content3img2,
			content3img3,
			content3img4,
			content3img1en,
			content3img2en,
			content3img3en,
			content3img4en,
			selectedLang: sessionStorage.getItem('language') || 'ko',
		};
	},

	components: { Hooper, Slide, HooperPagination },
	computed: {
		slidesLen() {
			return this.slides.length;
		},
		isBgblue() {
			if (this.visibleSlide === 0 || this.visibleSlide === 2) {
				return true;
			} else {
				return false;
			}
		},
		computedValue() {
			return this.$store.state.language;
		},
	},
	mounted() {
		const disablePopupUntil = localStorage.getItem('disablePopupUntil');
		const currentDate = new Date();
		window.addEventListener('storage', this.handleStorageChange);

		if (disablePopupUntil && new Date(disablePopupUntil) > currentDate) {
			this.showPopup = false; // 팝업 창 닫기
		} else {
			this.showPopup = true; // 팝업 창 보이도록 설정
		}
	},
	methods: {
		closePopup() {
			this.showPopup = false; // 팝업 창 닫기
		},
		disablePopup() {
			this.showPopup = false; // 팝업 창 닫기

			const oneDayLater = new Date();
			oneDayLater.setDate(oneDayLater.getDate() + 1);
			localStorage.setItem('disablePopupUntil', oneDayLater.toString()); // 팝업을 하루 동안 보지 않기 설정 저장
		},
		showService1() {
			this.service = 1;
			this.isActive1 = true;
			this.isActive2 = false;
			this.isActive3 = false;
			this.isActive4 = false;
		},
		showService2() {
			this.service = 2;
			this.isActive1 = false;
			this.isActive2 = true;
			this.isActive3 = false;
			this.isActive4 = false;
		},
		showService3() {
			this.service = 3;
			this.isActive1 = false;
			this.isActive2 = false;
			this.isActive3 = true;
			this.isActive4 = false;
		},
		showService4() {
			this.service = 4;
			this.isActive1 = false;
			this.isActive2 = false;
			this.isActive3 = false;
			this.isActive4 = true;
		},
		changeMainImage1() {
			this.mainImage = 1;
		},
		changeMainImage2() {
			this.mainImage = 2;
		},
		changeMainImage3() {
			this.mainImage = 3;
		},
		next() {
			if (this.visibleSlide >= this.slidesLen - 1) {
				this.visibleSlide = 0;
			} else {
				this.visibleSlide++;
			}
			this.direction = 'left';
		},
		prev() {
			if (this.visibleSlide <= 0) {
				this.visibleSlide = this.slidesLen - 1;
			} else {
				this.visibleSlide--;
			}
			this.direction = 'right';
		},
	},
};
</script>

<style></style>
